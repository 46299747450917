import { View } from 'backbone.marionette';
import $ from 'jquery';
import Sortable from 'sortablejs';
import _ from 'underscore';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [Behaviors.LocaleSwitch],

  ui: {
    targetNodeList: '.js-target-node-list',
    groupNodeList : '.js-group-node-list'
  },

  events: {
    'cocoon:after-insert @ui.targetNodeList': 'onAfterInsertTargetNode',
    'cocoon:after-insert @ui.groupNodeList' : 'onAfterInsertGroupNode',
    'cocoon:after-remove @ui.groupNodeList' : 'onAfterRemoveGroupNode'
  },

  initialize() {
    this.bindUIElements();
    this._setGroupNos();
    this._applySortable();
  },

  onAfterInsertTargetNode() {
    this.bindUIElements();
    this._applySortable();
  },

  onAfterInsertGroupNode() {
    this._setGroupNos();
  },

  onAfterRemoveGroupNode() {
    this._setGroupNos();
  },

  _setGroupNos() {
    _(this.ui.groupNodeList).each((groupNodeList) => {
      const $rows = $(groupNodeList).find('tbody.nested-fields:visible');

      _($rows).each((row, i) => {
        const $row = this.$(row);
        $row.find('.js-hidden-no').val(i + 1);
      });
    });
  },

  _applySortable() {
    _(this.ui.groupNodeList).each((groupNodeList) => {
      Sortable.create(groupNodeList, {
        draggable: '.nested-fields',
        handle   : 'td.js-no',
        onUpdate : () => {
          this._setGroupNos();
        }
      });
    });
  }
});
