// MentorAPIを Select2 を用いて使う際の追加オプション指定を各所で利用する事を想定して定義したもの
// 必要な場合には利用時にオプションで渡すこと
//
// --- 利用例 -------------------------------------------------
// Utils.Select2.ajax(this.ui.mentorField, {
//   templateResult: Utils.Select2MentorApi.templateTeacherPriorityResult
// });
// -----------------------------------------------------------
import $ from 'jquery';

export default {
  // 在籍区分や教職員区分をlabelとして追加するための描画オプション
  // 特定の在籍教員など絞り込んだ抽出の場合は不要だが、横断する時にはこの手の情報を載せると候補を選ぶ際に利便性が上がる
  // 本処理はその上で、教員併任を基本（青、優先）として職員を許容するデザインの表示をする関数オプションである
  templateTeacherPriorityResult(datum) {
    const beingColor = ['在籍', 'Enrolled'].includes(datum.being) ? 'info' : 'warning';
    const beingLabel = $(`<span class="label label-${beingColor}" style="margin-left:10px"></span>`)
                        .text(datum.being);
    const typeColor = ['職員', 'Staff'].includes(datum.type) ? 'warning' : 'info';
    const typeLabel = $(`<span class="label label-${typeColor}" style="margin-left:2px"></span>`)
                        .text(datum.type);

    return $('<span></span>')
      .append(datum.text)
      .append($('<span class="pull-right"></span>').append(beingLabel).append(typeLabel));
  }
};
