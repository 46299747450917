import _ from 'underscore';
import { View } from 'backbone.marionette';
import autosize from 'autosize';
import Sortable from 'sortablejs';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.LocaleSwitch
  ],

  events: {
    'change .js-plan-item-format'              : 'onChangePlanItemFormat',
    'cocoon:after-insert .js-plan-items'       : 'onAfterInsertPlanItem',
    'cocoon:after-remove .js-plan-items'       : 'onAfterRemovePlanItem',
    'cocoon:after-insert .js-plan-item-choices': 'onAfterInsertPlanItemChoice'
  },

  initialize() {
    this.showOrHidePlanItemChoices();
    this._setNos();
    this._applySortable();
  },

  onChangePlanItemFormat() {
    this.showOrHidePlanItemChoices();
  },

  onAfterInsertPlanItem(e) {
    autosize(this.$(e.currentTarget).find('textarea'));
    this.showOrHidePlanItemChoices();
    this._setNos();
  },

  onAfterRemovePlanItem() {
    this._setNos();
  },

  onAfterInsertPlanItemChoice(e) {
    autosize(this.$(e.currentTarget).find('textarea'));
  },

  showOrHidePlanItemChoices() {
    _(this.$('.js-plan-item-format')).each((el) => {
      const $planItemFormat = this.$(el);
      const $planItemChoices = $planItemFormat.closest('tbody').find('.js-plan-item-choices');

      if (_(['select_single', 'select_multiple']).contains($planItemFormat.val())) {
        $planItemChoices.find('input, textarea, select').attr('disabled', false);
        $planItemChoices.show();
      } else {
        $planItemChoices.hide();
        $planItemChoices.find('input, textarea, select').attr('disabled', true);
      }
    });
  },

  _setNos() {
    const $rows = this.$('.js-plan-item-node:visible');

    _($rows).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-hidden-no').val(i + 1);
    });
  },

  _applySortable() {
    Sortable.create(this.$('.js-plan-items').get(0), {
      draggable: '.nested-fields',
      handle   : 'td.js-no',
      onUpdate : () => {
        this._setNos();
      }
    });
  }
});
