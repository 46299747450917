import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import _ from 'underscore';

export default View.extend({
  template: false,

  ui: {
    detailsCocoon   : '.js-details-cocoon_body',
    detailsEntryType: '.js-details-select-entry_type'
  },

  events: {
    'cocoon:after-insert @ui.detailsCocoon': 'onDetailsAfterInsert',
    'cocoon:after-remove @ui.detailsCocoon': 'onDetailsAfterRemove'
  },

  onRender() {
    this._setNos();
    this._applySortable();
  },

  onDetailsAfterInsert() {
    this._setEntryType();
    this._setNos();
    this.render();
  },

  onDetailsAfterRemove() {
    this._setNos();
  },

  _setEntryType() {
    const $lastField = this.ui.detailsCocoon.find('.nested-fields').last();
    const $targetText = $lastField.find('.js-entry_type_text');
    const $targetValue = $lastField.find('.js-entry_type');
    $targetText.text(this.ui.detailsEntryType.find(':selected').text());
    $targetValue.val(this.ui.detailsEntryType.selectpicker('val'));
  },

  _setNos() {
    _(this.ui.detailsCocoon).each((detailsCocoon) => {
      const $rows = this.$(detailsCocoon).find('tr.nested-fields:visible');

      _($rows).each((row, i) => {
        const $row = this.$(row);
        $row.find('.js-hidden-no').val(i);
      });
    });
  },

  _applySortable() {
    _(this.ui.detailsCocoon).each((detailsCocoon) => {
      Sortable.create(detailsCocoon, {
        draggable: '.nested-fields',
        handle   : 'td.js-sort-no',
        onUpdate : () => {
          this._setNos();
        }
      });
    });
  }
});
