// シラバスの開講年度を変更すると
// 年度に紐づくテンプレートの基本項目にて配置しない項目が邪魔なので非表示にしたい場合がある
// 制御対象の dom の data-layoutables に年度別の配置フラグ情報を入れて、このBehaviorを利用すること
//
// ---- 例 -------------------------------------------------------------------------------------
// - yearly_fixed_layoutables = SyllabusTemplateBasic.decorate.yearly_fixed_layoutables
//= search_form_for(@q, url: '#', html: { class: 'js-form-ransack' }) do |f|
//  = f.input :by_period, required: false, label: false, placeholder: t('.by_period'),
//             wrapper_html: { data: { layoutables: yearly_fixed_layoutables.fetch(:period) } }
// ---------------------------------------------------------------------------------------------
import $ from 'jquery';
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({

  ui: {
    fiscalYear: '#q_fiscal_year_eq',
    fields    : '.js-form-ransack [data-layoutables]'
  },

  events: {
    'change @ui.fiscalYear' : 'onChangeFiscalYear',
    'reset .js-form-ransack': 'onClickReset' // app/backbone/mixins/datatables.jsに依存
  },

  onRender() {
    this._layoutFixedFields();
  },

  onChangeFiscalYear() {
    this._layoutFixedFields();
  },

  onClickReset() {
    // リセット処理後に開講年度が変更されるまで待機する
    setTimeout(() => {
      this._layoutFixedFields();
    }, 200);
  },

  _layoutFixedFields() {
    const year = this.ui.fiscalYear.val();

    $.each(this.ui.fields, (_i, el) => {
      const $field = this.$(el);
      const layoutables = $field.data('layoutables');
      const isHidden = year ? layoutables[year] === false : _.all(_.values(layoutables), false);
      $field.toggleClass('hidden', isHidden);
    });
  }
});
