import _ from 'underscore';
import $ from 'jquery';
import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';
import Utils from '../../../../../utils';
import Behaviors from '../../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabuses.copied_contents.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableDropAllow,
    Behaviors.DataTableResizer
  ],

  ui: {
    select2   : '.js-select2',
    fiscalYear: '.js-fiscal_year',
    belong    : '.js-belong',
    copy      : '.js-copy'
  },

  events: {
    'click @ui.copy': 'onClickCopy',
    'page.dt table' : 'onChangeTablePage'
  },

  onRender() {
    Utils.Select2.ajax(this.ui.select2, {
      allowClear       : true,
      dropdownAutoWidth: true,
      templateResult   : Utils.Select2MentorApi.templateTeacherPriorityResult
    });

    this.initDataTables({
      columns: this.columns(),
      order  : [[1, 'desc'], [3, 'asc'], [2, 'asc']],
      select : {
        style   : 'single',
        selector: 'td:first-child'
      }
    });
    this.dataTable.on('select', this.onSelectTable.bind(this));
    this.dataTable.on('deselect', this.onDeselectTable.bind(this));
  },

  onReset() {
    this.ui.select2.val(null).trigger('change');
  },

  onSelectTable() {
    this.ui.copy.removeClass('disabled');
  },

  onDeselectTable() {
    this.ui.copy.addClass('disabled');
  },

  onChangeTablePage() {
    // ページを変えるとチェック状態がリセットされる。
    this.ui.copy.addClass('disabled');
  },

  onClickCopy(e) {
    e.preventDefault();
    e.stopPropagation();

    const data = _(this.dataTable.rows({ selected: true }).data()).first();
    if (!data) return;
    const url = Utils.Url.buildUrl(this.ui.copy.prop('href'), { src_syllabus_id: data.id });
    this.ui.copy.prop('href', url);

    if (Number(data.content_count) === 0) {
      $.rails.handleMethod(this.ui.copy);
      return;
    }

    Utils.Modal.confirm(this.t('.confirm'), (flg) => {
      if (!flg) return;

      $.rails.handleMethod(this.ui.copy);
    });
  },

  columns() {
    return [
      {
        class   : 'id select-checkbox',
        data    : 'id',
        sortable: false,
        render() {
          return '';
        }
      },
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 80
      },
      {
        class: 'open_terms',
        data : 'open_terms',
        width: 80
      },
      {
        class: 'subjects',
        data : 'subjects',
        width: 200
      },
      {
        class   : 'variable_code',
        data    : 'variable_code',
        sortable: false,
        width   : 80
      },
      {
        class   : 'belong',
        data    : 'belong',
        width   : 250,
        sortable: false
      },
      {
        class   : 'editors',
        data    : 'editors',
        sortable: false
      },
      {
        class   : 'instructors',
        data    : 'instructors',
        sortable: false
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 40
      },
      {
        class: 'inputted_at',
        data : 'inputted_at',
        width: 42
      },
      {
        class   : 'text-center',
        data    : 'show_path',
        sortable: false,
        width   : 30,
        render  : (val) => {
          return Utils.Render.showButton(this, val, val);
        }
      }
    ];
  }
}));
