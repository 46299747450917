import { View } from 'backbone.marionette';
import $ from 'jquery';
import _ from 'underscore';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    {
      behaviorClass: Behaviors.DataTableResizer,

      // display: fixed を指定しているdatatableはoffsetTopが意図した値を返さないので、
      // 他の要素から高さを算出して返すようにする。(ヘッダーの高さ + 余白)
      offsetTop: ((_this) => { return _this.ui.headerArea.outerHeight(true) + 14; })
    },
    Behaviors.SyllabusBasicFieldBuilder,
    Behaviors.SyllabusFixedFieldLayouter
  ],

  ui: {
    headerArea          : '.js-header',
    searchArea          : '.js-search',
    searchResult        : '.js-search-result',
    showLink            : '.js-show-link',
    showArea            : '.js-show',
    showResult          : '.js-show-result',
    showBackBtn         : '.js-show-back',
    collapseConditionBtn: '.js-collapse-syllabus-basic-conditions'
  },

  events: {
    'click @ui.showLink'            : 'onClickShowLink',
    'click @ui.showBackBtn'         : 'onClickShowBackBtn',
    'click @ui.collapseConditionBtn': 'onClickCollapseConditionBtn'
  },

  initialize()  {
    this._initResizeEvent();
    this.isSmartSize = Utils.Device.isSmartSize();
  },

  onRender() {
    this._initMobile();
    this._initDataTable();
  },

  onClickCollapseConditionBtn() {
    // ボタンを押した際に発生するレイアウトのズレを調整する
    setTimeout(() => {
      this._rebuildDataTable();
      this.render();
    }, 300);
  },

  // override
  // ページングの時に動いては困るためロード後イベントには定義できない
  // 検索実行時のみ、検索結果の先頭のシラバスを表示する
  onSearch(e) {
    e.preventDefault();
    this.reload(_.bind(this._autoShowFirstRow, this));
  },

  onClickShowLink(e) {
    e.preventDefault();
    this.ui.showArea.block();
    this._show($(e.target).attr('href'));
  },

  onClickShowBackBtn() {
    this._mobileScreenTransition(false);
  },

  resizeTimeoutId: 0,
  onResizeWindow() {
    // PC版とスマートフォン版では大きくUIが異なるため、切り替わり時に作り直しを行う
    clearTimeout(this.resizeTimeoutId);
    this.resizeTimeoutId = setTimeout(() => {
      if (this.isSmartSize !== Utils.Device.isSmartSize()) {
        this._rebuildDataTable();
        this.render();
        this.isSmartSize = Utils.Device.isSmartSize();
      }
    }, 500);
  },

  _initDataTable() {
    const scrollOption = Utils.Device.isPcSize() ? {} : { scrollY: false };
    this.initDataTables(_.defaults({
      columns       : this._columns(),
      ordering      : false,
      deferRender   : true,
      scrollCollapse: true,
      scroller      : Utils.Device.isPcSize(),
      initComplete  : () => {
        this.bindUIElements();
        this._autoShowFirstRow();
        this._adjustBodyOffsetTop();
        this.trigger('resizeDataTable');
      }
    }, scrollOption));
  },

  _columns() {
    return [
      {
        class: 'name',
        data : 'name',
        width: 80
      }
    ];
  },

  // 自動で１行目のシラバスを選択して表示するための関数
  // ただしスマホはレイアウトの挙動上、自動で選択すると混乱を招くため実施しない
  _autoShowFirstRow() {
    if (Utils.Device.isSmartSize()) return;

    this.bindUIElements();
    const $link = this.ui.showLink.first();
    if ($link[0]) {
      this._show($link.attr('href'));
    } else {
      this.ui.showArea.html('');
    }
  },

  _rebuildDataTable() {
    if (this.dataTable) {
      const tableHtml = this.ui.dataTables.clone().prop('outerHTML');
      this.dataTable.destroy(true);
      this.ui.searchResult.html(tableHtml);
      this.bindUIElements();
    }
  },

  _show(url) {
    if (Utils.Device.isSmartSize()) { this._showMobile(); }
    this.ui.showArea.block();
    $.getJSON(url)
      .done((data) => {
        this.ui.showArea.html(data.html);
        this.ui.showArea.find('[data-toggle="tooltip"]').tooltip({
          container: 'body'
        });
        $(window).scrollTop(0);
      }).always(() => { this.ui.showArea.unblock(); });
  },

  _initResizeEvent() {
    const eventName = Utils.Device.isPC() ? 'resize' : 'orientationchange';
    $(window).on(`${eventName}.syllabus`, _.bind(this.onResizeWindow, this));
  },

  _adjustBodyOffsetTop() {
    this.ui.searchResult.css('top', `${this._bodyOffsetTop()}px`);
    this.ui.showResult.css('top', `${this._bodyOffsetTop()}px`);
  },

  // ヘッダーの高さ + 余白
  _bodyOffsetTop() {
    return this.behaviors[0].offsetTop(this);
  },

  _initMobile() {
    const flg = Utils.Device.isSmartSize();
    this.ui.showResult.toggle(!flg);
    this.ui.showBackBtn.toggle(flg);
    this.ui.searchArea.show();
    this.ui.searchResult.show();
  },

  _showMobile() {
    this._mobileScreenTransition(true);
  },

  _mobileScreenTransition(flg) {
    this.ui.searchArea.toggle(!flg);
    this.ui.searchResult.toggle(!flg);
    this.ui.showResult.toggle(flg);
  }
}));
