import _ from 'underscore';
import Utils  from '../../../../utils';

export default {
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabuses.edit'),

  ui: {
    subjectsAssigns    : '.js-subjects-assigns',
    copyAssigns        : '.js-copy-assigns',
    editingAssigns     : '.js-editings-assigns',
    instructingsAssigns: '.js-instructings-assigns',
    node               : '.js-node',
    belongs            : '.js-belongs',
    template           : 'select.js-select-template',
    uiAreaBlock        : '.js-ui-area-block',
    selectpicker       : 'select.selectpicker'
  },

  events: {
    'submit @ui.form'             : 'onSubmit',
    'click @ui.copyAssigns'       : 'onClickCopyAssigns',
    'cocoon:after-insert @ui.node': 'onAfterInsertNode',
    'change @ui.template'         : 'onChangeTemplate'
  },

  onRender() {
    // テンプレートが選択されたら他の入力欄のブロックを解除する
    if (this.ui.template.val() !== '') {
      this.ui.uiAreaBlock.removeClass('ui-area-block');
    }
    this.ui.belongs.selectpicker();

    Utils.Select2.ajax(this.ui.subjectsAssigns, { sortable: true });
    Utils.Select2.ajax(this.ui.editingAssigns, {
      sortable      : true,
      templateResult: Utils.Select2MentorApi.templateTeacherPriorityResult
    });
    Utils.Select2.ajax(this.ui.instructingsAssigns, {
      sortable      : true,
      templateResult: Utils.Select2MentorApi.templateTeacherPriorityResult
    });

    _.defer(() => {
      this.addTooltipToDisabledSelectTemplate();
    });
  },

  onAfterInsertNode() {
    this.bindUIElements();
    this.ui.belongs.selectpicker();
    this.ui.selectpicker.selectpicker();
  },

  addTooltipToDisabledSelectTemplate() {
    this
      .$('.js-select-template.disabled')
      .tooltip({
        title: this.t('.disabled_select_template')
      });
  },

  onClickCopyAssigns() {
    this.ui.instructingsAssigns.empty();
    const editors = this.ui.editingAssigns.select2('data');
    const options = editors.map(editor => {
      return new Option(editor.text, editor.id, false, true);
    });
    this.ui.instructingsAssigns.append(options).trigger('change');
  },

  onChangeTemplate() {
    const templateId = this.ui.template.val();
    const url = Utils.Url.buildUrl(this.ui.template.data('path'),
                                   { syllabus_template_id: templateId });
    window.location.href = url;
  }
};
