import _ from 'underscore';
import $ from 'jquery';
import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.SortableRubric,
    Behaviors.LocaleSwitch
  ],

  ui: {
    rubricNodeInsert: '.js-rubric-node-insert',
    itemNodeList    : '.js-item-node-list'
  },

  events: {
    'cocoon:after-insert @ui.rubricNodeInsert': 'onAfterRubricNodeInsert',
    'cocoon:after-insert @ui.itemNodeList'    : 'onAfterItemNodeInsert',
    'cocoon:after-remove @ui.itemNodeList'    : 'onAfterItemNodeRemove'
  },

  initialize() {
    this.bindUIElements();
    this._setItemNos();
    this._applySortable();
  },

  onAfterRubricNodeInsert() {
    this.bindUIElements();
  },

  onAfterItemNodeInsert() {
    this._setItemNos();
  },

  onAfterItemNodeRemove() {
    this._setItemNos();
  },

  _setItemNos() {
    _(this.ui.itemNodeList).each((itemNodeList) => {
      const $rows = $(itemNodeList).find('tr.asm-mind__item-fields:visible');

      _($rows).each((row, i) => {
        const $row = this.$(row);
        $row.find('.js-hidden-no').val(i + 1);
      });
    });
  },

  _applySortable() {
    _(this.ui.itemNodeList).each((itemNodeList) => {
      Sortable.create(itemNodeList, {
        draggable: '.nested-fields',
        handle   : 'td.js-no',
        onUpdate : () => {
          this._setItemNos();
        }
      });
    });
  }
});
